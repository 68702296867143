<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Devices</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text @click="refresh()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>
        <provision-device></provision-device>
        <!-- <provision-device-universal></provision-device-universal> -->
      </div>
    </v-layout>
    <v-layout>
      <page-subtitle>
        Devices are used to run your applications. Learn more about devices and
        provisioning in the official
        <a
          href="https://docs.synpse.net/synpse-core/devices/provisioning"
          class="ml-1"
          target="_blank"
          rel="noopener"
          >documentation</a
        >.
      </page-subtitle>
    </v-layout>

    <v-layout>
      <v-alert
        dense
        border="left"
        type="warning"
        outlined
        v-if="
          project != undefined && project.deviceCount >= project.quota.devices
        "
      >
        You have reached your devices quota. To add a new device either delete
        an existing one or contact support to increase your project's quota.
      </v-alert>
    </v-layout>

    <!-- Error message -->
    <v-layout>
      <div v-if="error">
        <v-alert type="error" dense outlined>
          {{ error }}
        </v-alert>
      </div>
    </v-layout>

    <!-- delete confirmation modal -->
    <v-dialog v-model="removeDeviceModal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to delete device '{{ removingDevice.name }}'?
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="removeDeviceModal = false"
            class="primary--text"
            >Cancel</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="deleteDevice(removingDevice)"
            class="error white--text"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit labels -->
    <v-dialog v-model="editLabelsModal" max-width="400px">
      <v-card>
        <v-card-title>Edit Labels</v-card-title>
        <v-card-text>
          <v-list dense>
            <v-subheader>Current labels</v-subheader>
            <v-list-item
              v-for="(label, key, i) in editingDevice.labels"
              :key="`label-${key}-${i}`"
            >
              <v-list-item-content>
                <v-chip outlined color="primary" class="justify-center">
                  {{ key }}:{{ label }}
                </v-chip>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn icon color="error" @click="deleteDeviceLabel(key)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-title>Add New</v-card-title>
        <v-card-text>
          <form v-on:submit.prevent="addDeviceLabel()">
            <v-text-field required label="Key" v-model="newLabel.key" />
            <v-text-field required label="Value" v-model="newLabel.value" />
            <v-btn v-show="false" hidden type="submit"></v-btn>
          </form>
          <div v-if="error">
            <v-alert error close v-model="error">
              {{ error }}
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="closeDeviceEditModal()"
            class="primary--text"
            >Close</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="addDeviceLabel()"
            class="primary white--text"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="mt-2">
      <div class="tbcard">
        <!-- might be just filtering out all of them -->
        <div
          v-if="
            !loading &&
            devices.length === 0 &&
            filter === '' &&
            !projectHasDevices
          "
        >
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex md6 xs7 class="text-md-center mt-5">
                <span class="headline">Register your first device to Synpse</span>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex md6 xs12 class="text-md-left mt-5">
                <ol>
                  <li class="ma-3">
                    Connect to your device using SSH or through a web UI and open a
                    terminal.
                  </li>
                  <li class="ma-3">
                    <span>Run the following command to add the device to the project:</span>
                    <markup class="mt-5" language="bash" :code="installCommand" :inline="false"></markup>
                  </li>
                </ol>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Filter devices by name or labels"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :key="tableKey"
            :headers="headers"
            :items="devices"
            hide-default-footer
            :server-items-length="pagination.totalItems"
            :loading="loading"
            loading-text="Loading... Please wait"
            :search="e3"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | moment }}
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <router-link
                :to="{
                  name: 'deviceDetails',
                  params: {
                    project: item.projectId,
                    device: item.id,
                  },
                }"
                >{{ item.name }}</router-link
              >
            </template>

            <template v-slot:[`item.status`]="{ item }">
              {{ item.status }}
              <v-icon
                v-if="item.status == 'online'"
                small
                color="green accent-3"
                >fiber_manual_record</v-icon
              >
              <v-icon v-else-if="item.status == 'offline'" small color="error"
                >fiber_manual_record</v-icon
              >
            </template>

            <template v-slot:[`item.labels`]="{ item }">
              <v-chip
                outlined
                v-for="(key, label, i) in item.labels"
                :key="`label-${key}-${i}`"
                color="primary"
                class="mr-1"
                >{{ label }}:{{ item.labels[label] }}</v-chip
              >
            </template>

            <template v-slot:[`item.ip`]="{ item }">
              {{ item.info.ipAddress }}
            </template>

            <template v-slot:[`item.os`]="{ item }">
              {{ item.info.osRelease.prettyName }}
            </template>

            <template v-slot:[`item.agentVersion`]="{ item }">
              {{ item.info.agentVersion }}
            </template>

            <template v-slot:[`item.lastSeenAt`]="{ item }">
              <v-tooltip top :key="item.id">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.lastSeenAt | ago }}
                  </span>
                </template>
                <span>{{ item.lastSeenAt | date }}</span>
              </v-tooltip>
            </template>

            <template class="pr-2 pb-2" v-slot:[`item.deviceMemory`]="{ item }">
              <v-sheet v-if="metricsLoading" width="100">
                <v-progress-linear
                  indeterminate
                  class="white--text text-darken-2 mb-2 caption"
                  color="grey darken-2"
                >
                </v-progress-linear>
              </v-sheet>

              <v-sheet
                v-else-if="getDeviceMemPercentage(item.id) > 0"
                width="100"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-linear
                      v-bind="attrs"
                      v-on="on"
                      class="white--text text-darken-1 mb-2 caption"
                      height="30"
                      :color="getMetricsColor(getDeviceMemPercentage(item.id))"
                      width="200"
                      :value="getDeviceMemPercentage(item.id)"
                    >
                      {{ getDeviceUsedMem(item.id) }} /
                      {{ getDeviceMemTotal(item.id) }} GB
                    </v-progress-linear>
                  </template>
                  <span>Average over the last 1 minutes</span>
                </v-tooltip>
              </v-sheet>
              <!-- Showing N/A metrics -->
              <v-sheet v-else width="100">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-linear
                      v-bind="attrs"
                      v-on="on"
                      class="white--text text-darken-1 mb-2 caption"
                      height="30"
                      :color="getMetricsColor(getDeviceMemPercentage(item.id))"
                      width="200"
                      value="0"
                    >
                      N/A
                    </v-progress-linear>
                  </template>
                  <span>Metrics not available</span>
                </v-tooltip>
              </v-sheet>
            </template>

            <template v-slot:[`item.deviceCPU`]="{ item }">
              <v-sheet v-if="metricsLoading" width="100">
                <v-progress-linear
                  indeterminate
                  class="white--text text-darken-2 mb-2 caption"
                  color="grey darken-2"
                >
                </v-progress-linear>
              </v-sheet>

              <v-sheet v-else-if="getCPUUtilisation(item.id)" width="100">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-linear
                      v-bind="attrs"
                      v-on="on"
                      class="white--text text-darken-2 mb-2 caption"
                      height="30"
                      :color="getMetricsColor(getCPUUtilisation(item.id))"
                      :value="getCPUUtilisation(item.id)"
                    >
                      {{ getCPUUtilisation(item.id) }} %
                    </v-progress-linear>
                  </template>
                  <span>Average over the last 1 minutes</span>
                </v-tooltip>
              </v-sheet>
              <!-- Showing N/A metrics -->
              <v-sheet v-else width="100">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-linear
                      v-bind="attrs"
                      v-on="on"
                      class="white--text text-darken-2 mb-2 caption"
                      height="30"
                      :color="getMetricsColor(getCPUUtilisation(item.id))"
                      value="0"
                    >
                      N/A
                    </v-progress-linear>
                  </template>
                  <span>Metrics not available</span>
                </v-tooltip>
              </v-sheet>
            </template>

            <template v-slot:[`item.ssh`]="{ item }">
              <v-btn
                x-small
                outlined
                :disabled="item.status != 'online'"
                :to="{
                  name: 'deviceSSH',
                  params: {
                    project: item.projectId,
                    device: item.id,
                  },
                }"
                router
              >
                SSH
              </v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu class="items" top left transition="v-slide-y-transition">
                <template v-slot:activator="{ on: on }">
                  <v-btn
                    icon
                    v-on="on"
                    :class="
                      $store.state.theme === 'light' ? 'secondary--text' : ''
                    "
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    :disabled="item.status != 'online'"
                    :to="{
                      name: 'deviceSSH',
                      params: {
                        project: item.projectId,
                        device: item.id,
                      },
                    }"
                    router
                  >
                    <v-list-item-title>SSH</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :to="{
                      name: 'deviceDetails',
                      params: {
                        project: item.projectId,
                        device: item.id,
                      },
                    }"
                    router
                  >
                    <v-list-item-title>Details</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openDeviceEditModal(item)">
                    <v-list-item-title>Edit labels</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="removeDeviceIntent(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <div class="pr-2 pb-2">
            <v-layout class="mt-2">
              <v-spacer></v-spacer>
              <span class="text-caption ma-2 mr-4">Rows per page:</span>
              <div class="rows-select">
                <v-flex shrink class="select">
                  <v-select
                    class="mb-2"
                    width="45"
                    v-model="itemsPerPage"
                    :items="itemsPerPageOptions"
                    dense
                    label="Rows per page"
                    single-line
                  ></v-select>
                </v-flex>
              </div>
              <span class="text-caption ma-2 ml-4"
                >Total devices: {{ pagination.totalItems }}</span
              >
              <v-btn
                class="mr-2"
                icon
                router
                :disabled="pagination.previousPageToken == '' ? true : false"
                :to="{
                  name: 'devices',
                  query: {
                    pageToken: pagination.previousPageToken,
                  },
                }"
              >
                <v-icon>navigate_before</v-icon>
              </v-btn>
              <!-- Navigate to the next page based on the token -->
              <v-btn
                class="ml-2"
                icon
                router
                :disabled="pagination.nextPageToken == '' ? true : false"
                :to="{
                  name: 'devices',
                  query: {
                    pageToken: pagination.nextPageToken,
                  },
                }"
              >
                <v-icon>navigate_next</v-icon>
              </v-btn>
            </v-layout>
          </div>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}

.rows-select {
  // max-width: 100%;
  // width: 60px;
  min-width: 70px;
}

.v-select__selections input {
  display: none;
}
</style>

<script>
import Markup from '../helpers/Markup';
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
// import DeviceEditLabels from './DeviceEditLabels.vue';
import ProvisionDevice from './ProvisionDevice.vue';
// import ProvisionDeviceUniversal from './ProvisionDeviceUniversal.vue';

export function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export default {
  components: {
    PageSubtitle,
    PageTitle,
    ProvisionDevice,
   // ProvisionDeviceUniversal,
    Markup,
    // DeviceEditLabels,
  },
  data() {
    return {
      sseConn: null,
      mounted: false,
      tableKey: '',
      filter: '',
      metricsLoading: false,
      projectHasDevices: false,
      itemsPerPage: 20,
      itemsPerPageOptions: [
        10,
        20,
        50,
        200,
        1000
      ],
      e3: null,
      removeDeviceModal: false,
      removingDevice: {
        id: ''
      },
      // Device labels edit
      editLabelsModal: false,
      editingDevice: {
        id: '',
        projectId: ''
      },
      newLabel: {
        key: '',
        value: ''
      },
      selectedTokenID: '',
    }
  },

  watch: {
    // Table pagination
    itemsPerPage: {
      handler() {
        // If user is changing items per page, we kinda need
        // to reset the token, otherwise going backwards might
        // be weird
        let reset = this.itemsPerPage !== this.pagination.pageSize ? true : false
        this.$store.dispatch('SetDevicesPageSize', this.itemsPerPage).then(() => {
          if (reset && this.pageTokenFromUrl) {
            this.$router.push({ name: 'devices', params: { projectId: this.projectId } }).catch(() => { })
          } else {
            this.fetchDevices(this.filter)
            this.fetchDevicesMetrics(this.filter)
          }
        })
      }
    },
    // Debouncing is needed to avoid calling API multiple times while
    // the user is still typing and we call it immediately if the filter
    // is removed
    filter: debounce(function (newVal) {
      this.fetchDevices(newVal)
      this.fetchDevicesMetrics(this.filter)
    }, 1000),
    devices: {
      handler() {
        if (this.devices.length > 0) {
          this.projectHasDevices = true
        }
      }
    },
    $route() {
      this.fetchDevices(this.filter)
      this.fetchDevicesMetrics(this.filter)
    }
  },

  computed: {
    loading() {
      return this.$store.state.device.loading
    },
    error() {
      return this.$store.state.device.error
    },
    devices() {
      return this.$store.state.device.devices
    },
    pagination() {
      return this.$store.state.device.devicesPagination
    },
    device() {
      return this.$store.state.device.device
    },
    devicesMetrics() {
      return this.$store.state.device.devicesMetrics
    },
    projectId() {
      return this.$route.params.project
    },
    pageTokenFromUrl() {
      return this.$route.query.pageToken
    },
    project() {
      return this.$store.state.project.project
    },
    registrationTokens() {
      return this.$store.state.device.registrationTokens
    },
    selectedToken() {
      if (this.selectedTokenID === '' && this.registrationTokens.length > 0) {
        return this.registrationTokens[0]
      } 
      const selectedTokenId = this.selectedTokenID
      const found = this.registrationTokens.find(token => token.id === selectedTokenId)
      return found
    },
    headers() {
      let headers = [{ text: 'Name', align: 'left', value: 'name', sortable: false },
        { text: 'Status', value: 'status', align: 'left', sortable: false },
        { text: 'Labels', value: 'labels', align: 'left', sortable: false },
        { text: 'IP address', value: 'ip', align: 'left', sortable: false },
        { text: 'OS', value: 'os', align: 'left', sortable: false },
        { text: 'Agent version', value: 'agentVersion', align: 'left', sortable: false },
        { text: 'Last seen', value: 'lastSeenAt', align: 'left', sortable: false }]

      // returning with RAM and CPU columns
      if (this.project.quota.metricsEnabled) {
        headers.push(
          { text: 'RAM', value: 'deviceMemory', align: 'left', sortable: false },
          { text: 'CPU', value: 'deviceCPU', align: 'left', sortable: false },
        )
      }
      headers.push(
        { text: '', value: 'ssh', sortable: false, align: 'center' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      )
      return headers
    },
    installCommand() {
      // some crappy formatting
      return `curl https://downloads.synpse.net/install.sh | \\\n  AGENT_PROJECT=${this.projectId} \\\n  AGENT_REGISTRATION_TOKEN=${this.selectedToken?.id} \\\n  AGENT_CONTROLLER_URI=${window.location.protocol}//${window.location.host}/api \\\n  bash`
    }
  },

  async mounted() {
    this.mounted = true
    this.subscribe()

    // Loading the project to get the quota
    let q = {
      projectId: this.projectId
    }
    await this.$store.dispatch('GetProject', q)

    // Loading pagination preferences
    await this.$store.dispatch('LoadDevicesPageSize')
    this.itemsPerPage = this.pagination.pageSize

    // Loading devices data
    await this.refresh()
    if (this.devices.length > 0) {
      this.projectHasDevices = true
    }
  },

  beforeDestroy() {
    this.$store.dispatch('UnsubscribeFromSSE')
  },

  beforeUnmount() {
    this.$store.dispatch('UnsubscribeFromSSE')
  },

  methods: {
    async refresh() {
      // list devices
      this.fetchDevices(this.filter)
      // list devices metrics using same query
      this.fetchDevicesMetrics(this.filter)

      let q = {
        projectId: this.projectId
      }
      this.$store.dispatch('ListDeviceRegistrationTokens', q)
    },
    async fetchDevices(filter) {
      let q = {
        projectId: this.projectId,
        query: filter,
        pageSize: this.pagination.pageSize,
        pageToken: this.pageTokenFromUrl ? this.pageTokenFromUrl : '',
      }
      await this.$store.dispatch('GetDevices', q)
    },
    async fetchDevicesMetrics(filter) {
      // Do not try to fetch metrics if they are disabled on the server
      if (!this.project.quota.metricsEnabled) {
        return
      }
      this.metricsLoading = true
      let q = {
        projectId: this.projectId,
        query: filter,
        pageSize: this.pagination.pageSize,
        pageToken: this.pageTokenFromUrl ? this.pageTokenFromUrl : '',
      }
      await this.$store.dispatch('GetDevicesMetrics', q)
      this.metricsLoading = false
    },
    // same as fetchDevices but instructs not to show the loader
    backgroundFetchDevicesMetadata() {
      let q = {
        projectId: this.projectId,
        query: this.filter,
        pageSize: this.pagination.pageSize,
        pageToken: this.pageTokenFromUrl ? this.pageTokenFromUrl : '',
        background: true
      }
      this.$store.dispatch('GetDevices', q)
      this.$store.dispatch('GetDevicesMetrics', q)
    },
    getDeviceFreeMem(device) {
      if (this.devicesMetrics[device] != undefined) {
        return this.devicesMetrics[device].memFree
      }
      return 0
    },
    getDeviceUsedMem(device) {
      if (this.devicesMetrics[device] != undefined) {
        return this.devicesMetrics[device].memUsed
      }
      return 0
    },
    getDeviceMemPercentage(device) {
      if (this.devicesMetrics[device] != undefined) {
        return this.devicesMetrics[device].memUsedPercent
      }
      return 0
    },
    getDeviceMemTotal(device) {
      if (this.devicesMetrics[device] != undefined) {
        return this.devicesMetrics[device].memTotal
      }
      return 0
    },
    getCPUUtilisation(device) {
      if (this.devicesMetrics[device] != undefined) {
        return this.devicesMetrics[device].cpuUtilisation
      }
      return 0
    },
    getMetricsColor(usage) {
      if (usage === 0) {
        return 'grey darken-2'
      }
      if (usage < 70) {
        return 'green accent-3'
      }
      if (usage < 80) {
        return 'amber lighten-1'
      }

      // return 'pink lighten-1'
      return 'red darken-3'
    },
    subscribe() {
      let token = this.$store.getters.jwt
      let streamUrl = `/api/projects/${this.projectId}/events?_t=${token}&stream=${this.projectId}:devices`

      let q = {
        stream: streamUrl,
        callback: this.backgroundFetchDevicesMetadata
      }

      this.$store.dispatch('SubscribeToSSE', q)
    },
    // Debounce is used for device filtering
    debounce(fn, delay) {
      var timeoutID = null
      return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this

        // No filter supplied, calling immediately
        if (this.filter === '') {
          fn.apply(that, args);
          return
        }
        timeoutID = setTimeout(function () {
          fn.apply(that, args)
        }, delay)
      }
    },
    removeDeviceIntent: function (removingDevice) {
      this.removingDevice = removingDevice
      this.removeDeviceModal = true
    },
    deleteDevice: function (removingDevice) {
      this.$store.dispatch('DeleteDevice', removingDevice).then(() => {
        this.refresh()
        // closing confirmation modal
        this.removeDeviceModal = false
      })
    },
    openDeviceEditModal(device) {
      this.editLabelsModal = true
      this.editingDevice = device
      console.log('open')
    },
    closeDeviceEditModal() {
      this.editLabelsModal = false
      this.refresh()
    },
    async deleteDeviceLabel(key) {
      let payload = {
        projectId: this.editingDevice.projectId,
        deviceId: this.editingDevice.id,
        key: key,
      }
      await this.$store.dispatch('DeleteDeviceLabel', payload)

      let q = {
        projectId: this.editingDevice.projectId,
        deviceId: this.editingDevice.id,
      }
      await this.$store.dispatch('GetDevice', q)
      // refreshing device
      this.editingDevice = this.device
    },
    async addDeviceLabel() {
      let payload = {
        projectId: this.editingDevice.projectId,
        deviceId: this.editingDevice.id,
        values: { // backend expects a list of labels
          values: [this.newLabel]
        }
      }
      await this.$store.dispatch('SetDeviceLabel', payload)

      if (this.error === null) {
        let q = {
          projectId: this.editingDevice.projectId,
          deviceId: this.editingDevice.id,
        }
        await this.$store.dispatch('GetDevice', q)
        // refreshing device
        this.editingDevice = this.device

        this.newLabel.key = ''
        this.newLabel.value = ''
      }
    },
  }
}
</script>
