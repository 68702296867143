<template>
  <v-dialog id="dialog" v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text class="primary--text">
        <v-icon class="mr-1">mdi-server-plus</v-icon>
        Provision Device
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Provision Device</v-card-title>
      <v-card-text>
        
        <v-container fluid v-if="registrationTokens.length > 0">
          <v-row align="center">
            <v-select
              :items="registrationTokens"
              label="Registration Token"
              v-model="selectedTokenID"
             :hint="`used by ${selectedToken.deviceCount} devices`"
              item-text="name"
              item-value="id"
              solo
            ></v-select>
          </v-row>

          <v-row align="center" class="mb-2 mt-4">
            <markup language="bash" :code="installCommand" :inline="false"></markup>
          </v-row>

        </v-container>

        <v-container fluid v-else >
          <v-row align="center">
            <pre>
              Please create at least one device registration token before provisioning.
            </pre>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-on:click.native="dialog = false" class="primary">Close</v-btn>
        <v-spacer></v-spacer>  
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
</style>

<script>
import Markup from '../helpers/Markup';

  export default {
    components: {
      Markup
    },
    data () {
      return {
        dialog: false,

        selectedTokenID: ''
      }
    },

    computed: {
      loading() {
        return this.$store.state.device.loading
      },
      error() {
        return this.$store.state.device.error
      },
      projectId() {
        return this.$route.params.project
      },
      registrationTokens() {
        return this.$store.state.device.registrationTokens
      },
      selectedToken() {
        if (this.selectedTokenID === '' && this.registrationTokens.length > 0) {
          return this.registrationTokens[0]
        } 
        const selectedTokenId = this.selectedTokenID
        const found = this.registrationTokens.find(token => token.id === selectedTokenId)
        return found
      },    
      installCommand() {
        // some crappy formatting
        return `curl https://downloads.synpse.net/install.sh | \\\n  AGENT_PROJECT=${this.projectId} \\\n  AGENT_REGISTRATION_TOKEN=${this.selectedToken.id} \\\n  AGENT_CONTROLLER_URI=${window.location.protocol}//${window.location.host}/api \\\n  bash`
      }
    },

    methods: {
      refresh () {
        let q = {
          projectId: this.projectId
        } 
        this.$store.dispatch('ListDeviceRegistrationTokens', q)
      }
    }
  }
</script>
